import { Card } from '@johnlewispartnership/wtr-ingredients/ingredients/Card';
import classNames from 'classnames';
import React from 'react';

import Icon from 'components/wdx/Iconography';
import { FailedPaymentResolutionType } from 'constants/FailedPaymentResolutionType';
import { SlotTypes } from 'constants/fulfilmentTypes';
import orderStatus, { OrderStatus } from 'constants/orderStatus';
import { isSeasonalSlotDate } from 'utils/checkout/is-seasonal-slot-date';
import { OrderType } from 'utils/checkout/order-type';
import { formatAsPoundsOrPence } from 'utils/currency';
import { FORMAT, dayjs } from 'utils/date';

import styles from './PendingOrderSummaryV1.scss';
import StatusAndMenu from './StatusAndMenu';

export interface PendingOrderSummaryV1Props {
  address?: string;
  amendable: boolean;
  amending: boolean;
  amendOrderCutOff?: string;
  branchAddress?: string;
  onCancelChanges: () => void;
  orderId: string;
  slotEndTime: string;
  slotStartTime: string;
  slotType?: SlotTypes;
  orderType: OrderType | null;
  total?: number;
  status: OrderStatus;
  failedPaymentResolutionType: FailedPaymentResolutionType | null;
}

const PendingOrderSummaryV1 = ({
  address,
  amendable = false,
  amending,
  amendOrderCutOff,
  branchAddress,
  onCancelChanges,
  orderId,
  slotEndTime,
  slotStartTime,
  slotType,
  orderType,
  total,
  status,
  failedPaymentResolutionType,
}: PendingOrderSummaryV1Props) => {
  const isDelivery = slotType === SlotTypes.DELIVERY;
  const isYouShopWeDeliver = slotType === SlotTypes.DELIVERY_SERVICE;
  const isFailedPayment = status === orderStatus.PAYMENT_FAILED;
  const isSeasonalSlot = isSeasonalSlotDate(slotStartTime);
  const isOrderPaid = status === orderStatus.PAID;

  return (
    <Card data-testid={`pending-order-summary-id-${orderId}`} className={styles.noPadding}>
      <div className={styles.cardFlex}>
        <div aria-hidden>
          <Icon name="Clock" className={styles.clockIcon} />
        </div>
        <dl
          className={classNames(styles.summary, { [styles.amending]: amending })}
          data-order-id={orderId}
          data-slot-type={slotType}
        >
          <dt data-testid="fulfilment-information-label">
            {isDelivery || isYouShopWeDeliver
              ? 'Delivery date and time'
              : 'Collection date and time'}
          </dt>
          <dd data-testid="fulfilment-information-desc">
            <span>{dayjs(slotStartTime).tz().format(FORMAT.LONG_DAY_MONTH)} </span>
            <span>{`${dayjs(slotStartTime).tz().format(FORMAT.HOUR_AMPM)} - ${dayjs(slotEndTime)
              .tz()
              .format(` ${FORMAT.HOUR_AMPM}`)}`}</span>
          </dd>

          {isYouShopWeDeliver && (
            <>
              <dt data-testid="shop-at-address">Shop at</dt>
              <dd>
                <address data-cs-mask> {branchAddress}</address>
              </dd>
            </>
          )}
          {(isDelivery || isYouShopWeDeliver) && (
            <>
              <dt className={styles.deliveryAddress}>Delivery address</dt>
              <dd data-testid="delivery-address" className={styles.deliveryAddress}>
                <address data-cs-mask> {address}</address>
              </dd>
            </>
          )}

          {!(isDelivery || isYouShopWeDeliver) && branchAddress && (
            <>
              <dt data-testid="collection-address-label">Collect from</dt>
              <dd>
                <address data-cs-mask data-testid="collection-address">
                  {branchAddress}
                </address>
              </dd>
            </>
          )}

          {!isYouShopWeDeliver && !isFailedPayment && (
            <>
              <dt className={styles.orderTotals}>{`${
                isOrderPaid ? 'Total' : 'Estimated total'
              }`}</dt>
              <dd className={styles.orderTotals} data-testid="order-total">
                {formatAsPoundsOrPence(total)}
              </dd>
            </>
          )}
        </dl>
      </div>

      {!isYouShopWeDeliver && (
        <StatusAndMenu
          amending={amending}
          amendable={amendable}
          amendOrderCutOff={amendOrderCutOff}
          orderId={orderId}
          onCancelChanges={onCancelChanges}
          isSeasonalSlot={isSeasonalSlot}
          orderType={orderType}
          status={status}
          failedPaymentResolutionType={failedPaymentResolutionType}
        />
      )}
    </Card>
  );
};

export default PendingOrderSummaryV1;
